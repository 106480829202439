import dayjs from "dayjs";
import {DEMO_DATE_FORMAT, LANGUAGE_CONSTANT, PLACEHOLDER, SITE_LANG} from "../const";
import {isDemoMode} from "../utils";
import {LANG_SETTING} from "../settings";

export const setLocalMonthName = (date, formattedDate) => {
    const month = dayjs(date).format(PLACEHOLDER.MonthFormat);

    return formattedDate.replace(month, LANGUAGE_CONSTANT[SITE_LANG].month[month]);
};

export const getDateText = (date) => {
    let dateFormatted = dayjs(date).format(LANG_SETTING[SITE_LANG].DateFormat);

    if (SITE_LANG === 'ru') {
        dateFormatted = setLocalMonthName(date, dateFormatted);
    }

    return LANG_SETTING[SITE_LANG].DateText.replace(PLACEHOLDER.DateFormat, dateFormatted);
};

export const getLangDate = (date) => {
    if (isDemoMode()) {
        return (date) ? dayjs(date).format(DEMO_DATE_FORMAT) : date;
    }

    return (date) ? getDateText(date) : date;
};