import {LANGUAGE_CONSTANT, PLACEHOLDER, SITE_LANG} from "../const";
import {isDemoMode} from "../utils";
import {LANG_SETTING} from "../settings";

export const getRuMinLosText = (minlos) => {
    const minLosString = minlos.toString();
    const minLosEnd = minLosString[minLosString.length - 1];
    const twoToFourEnds = ['2', '3', '4'];
    const manyEnds = ['5', '6', '7', '8', '9', '0'];

    let minLosText = LANG_SETTING[SITE_LANG].MinLosText;
    let minLosDayEnd = '';
    let minLosDay = '';

    if (manyEnds.includes(minLosEnd)) {
        minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.fiveAndMore;
        minLosDay = LANGUAGE_CONSTANT['ru'].day.many;
    }

    if (twoToFourEnds.includes(minLosEnd)) {
        minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.twoToFour;
        minLosDay = LANGUAGE_CONSTANT['ru'].day.many;
    }

    if (minLosEnd === '1') {
        minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.one;
        minLosDay = LANGUAGE_CONSTANT['ru'].day.one;
    }

    switch (minlos) {
        case 11:
        case 12:
        case 13:
        case 14:
            minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.fiveAndMore;
            minLosDay = LANGUAGE_CONSTANT['ru'].day.many;
            break;
    }

    minLosText = minLosText.replace(PLACEHOLDER.MinLosEnd, minLosDayEnd);
    minLosText = minLosText.replace(PLACEHOLDER.MinLosDay, minLosDay);
    minLosText = minLosText.replace(PLACEHOLDER.MinLos, minlos);

    return minLosText;
};

export const getEnMinLosTest = (minlos) => {
    let minLosText = LANG_SETTING['en'].MinLosText;
    let minLosDay = minlos === 1 ? LANGUAGE_CONSTANT['en'].day.one : LANGUAGE_CONSTANT['en'].day.many;

    minLosText = minLosText.replace(PLACEHOLDER.MinLosDay, minLosDay);
    minLosText = minLosText.replace(PLACEHOLDER.MinLos, minlos);

    return minLosText;
};

export const getMinLosText = (minlos) => {
    switch (SITE_LANG) {
        case 'ru':
            return getRuMinLosText(minlos);
        case 'en':
            return getEnMinLosTest(minlos);
        default:
            return LANG_SETTING[SITE_LANG].MinLosText.replace(PLACEHOLDER.MinLos, minlos);
    }
};

export const getLangMinLos = (minlos) => {
    if (isDemoMode()) {
        return minlos;
    }

    return getMinLosText(minlos);
};