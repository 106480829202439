import {LANGUAGE_CONSTANT, PLACEHOLDER, SITE_LANG} from "../const";
import {isDemoMode} from "../utils";
import {LANG_SETTING} from "../settings";

export const getRuAdultsText = (adults) => {
    const adultsString = adults.toString();
    const adultsEnd = adultsString[adultsString.length - 1];
    const twoToFourEnds = ['2', '3', '4'];
    const manyEnds = ['5', '6', '7', '8', '9', '0'];

    let adultsText = LANG_SETTING[SITE_LANG].AdultsText;
    let minLosDayEnd = '';

    if (manyEnds.includes(adultsEnd)) {
        minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.fiveAndMore;
    }

    if (twoToFourEnds.includes(adultsEnd)) {
        minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.twoToFour;
    }

    if (adultsEnd === '1') {
        minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.one;
    }

    switch (adults) {
        case 11:
        case 12:
        case 13:
        case 14:
            minLosDayEnd = LANGUAGE_CONSTANT['ru'].numberEnding.fiveAndMore;
            break;
    }

    adultsText = adultsText.replace(PLACEHOLDER.AdultsEnd, minLosDayEnd);
    adultsText = adultsText.replace(PLACEHOLDER.Adults, adults);

    return adultsText;
};

export const getEnAdultsTest = (adults) => {
    let adultsText = LANG_SETTING['en'].AdultsText;
    let adultsEnd = parseInt(adults) === 1 ? LANGUAGE_CONSTANT['en'].guest.one : LANGUAGE_CONSTANT['en'].guest.many;

    adultsText = adultsText.replace(PLACEHOLDER.AdultsEnd, adultsEnd);
    adultsText = adultsText.replace(PLACEHOLDER.Adults, adults);

    return adultsText;
};

export const getAdultsText = (adults) => {
    switch (SITE_LANG) {
        case 'ru':
            return getRuAdultsText(adults);
        case 'en':
            return getEnAdultsTest(adults);
        default:
            return LANG_SETTING[SITE_LANG].AdultsText.replace(PLACEHOLDER.Adults, adults);
    }
};

export const getLangAdults = (adults) => {
    if (isDemoMode()) {
        return adults;
    }

    return getAdultsText(adults);
};