//@todo добить babel для запуска в IE. проблема в Fetch

// import "babel-polyfill";
import "regenerator-runtime/runtime";

import "../css/style.css";
import {priceHandler} from "./modules/price-handler";
import {fetchPrices} from "./modules/fetch-prices";
import {getPricesFromStorage, savePricesToStorage} from "./modules/local-storage";
import {isDemoMode} from "./utils";

const FETCH_PRICES_ERROR = 'Fetch prices error:';
const storagePrices = getPricesFromStorage();

document.addEventListener('DOMContentLoaded', () => {
    if (!storagePrices || isDemoMode()) {
        fetchPrices()
            .then((prices) => {
                savePricesToStorage(JSON.stringify(prices));
                priceHandler(prices);
            })
            .catch((error) => {
                console.log(FETCH_PRICES_ERROR);
                console.log(error);
            });
    } else {
        priceHandler(JSON.parse(storagePrices));
    }
});