import {
    ADULTS_ATTRIBUTE,
    CURRENCY_ATTRIBUTE,
    DATE_ATTRIBUTE, FILL_STATUS_ATTRIBUTE,
    HOTEL_ATTRIBUTE, LINK_ATTRIBUTE, LINK_BOOK_URL_ATTRIBUTE,
    MIN_LOS_ATTRIBUTE, OFFER_CODE_ATTRIBUTE, OFFER_NAME_ATTRIBUTE, RESTORE_STATUS_ATTRIBUTE,
    ROOM_TYPE_ATTRIBUTE
} from '../const';
import {isDemoMode} from "../utils";
import {getFormatPrice} from "./price-formating";
import {getLangDate} from "./lang-date";
import {getLangMinLos} from "./lang-minlos";
import {getLink} from "./link";
import {getLangOffer} from "./lang-offer";
import {getLangAdults} from "./lang-adults";

export const priceHandler = (prices) => {
    const moduleElements = document.querySelectorAll(`[${HOTEL_ATTRIBUTE}]`);

    moduleElements.forEach((field) => {
        const hotelId = field.getAttribute(HOTEL_ATTRIBUTE);
        const roomType = field.getAttribute(ROOM_TYPE_ATTRIBUTE);
        const adults = field.getAttribute(ADULTS_ATTRIBUTE);
        const currency = field.getAttribute(CURRENCY_ATTRIBUTE);

        const isDateField = field.hasAttribute(DATE_ATTRIBUTE);
        const isMinLosField = field.hasAttribute(MIN_LOS_ATTRIBUTE);
        const isOfferCodeField = field.hasAttribute(OFFER_CODE_ATTRIBUTE);
        const isOfferNameField = field.hasAttribute(OFFER_NAME_ATTRIBUTE);
        const isLinkField = field.hasAttribute(LINK_ATTRIBUTE);
        const hasRestoreStatusAttribute = field.hasAttribute(RESTORE_STATUS_ATTRIBUTE);
        const hasFillStatusAttribute = field.hasAttribute(FILL_STATUS_ATTRIBUTE);

        if (!prices[hotelId] || !prices[hotelId][roomType]) return;

        const roomPrice = (adults) ? prices[hotelId][roomType]['guests'][adults] : prices[hotelId][roomType]['minPrice'];

        if (!roomPrice) return;

        if (isDemoMode()) {
            if (hasRestoreStatusAttribute) {
                field.setAttribute(RESTORE_STATUS_ATTRIBUTE, roomPrice['isRestored'].toString());
                // return;
            }

            if (hasFillStatusAttribute) {
                field.setAttribute(FILL_STATUS_ATTRIBUTE, roomPrice['isFilled'].toString());
                // return;
            }

            if (isOfferCodeField) {
                field.innerHTML = roomPrice['ratePlan']['code'];
                return;
            }
        }

        if (isDateField) {
            field.setAttribute(DATE_ATTRIBUTE, roomPrice['date']);
            field.innerHTML = getLangDate(roomPrice['date']);
            return;
        }

        if (isMinLosField) {
            field.setAttribute(MIN_LOS_ATTRIBUTE, roomPrice['minLos']);
            field.innerHTML = getLangMinLos(roomPrice['minLos']);
            return;
        }

        if (isOfferNameField && roomPrice['ratePlan']) {
            field.setAttribute(MIN_LOS_ATTRIBUTE, roomPrice['ratePlan']['code']);
            field.innerHTML = getLangOffer(roomPrice['ratePlan']['names']);
            return;
        }

        if (isLinkField) {
            const linkOptions = field.getAttribute(LINK_ATTRIBUTE);
            const bookUrl = field.getAttribute(LINK_BOOK_URL_ATTRIBUTE);

            field.href = getLink(linkOptions, bookUrl, hotelId, roomType, adults, roomPrice['date'], roomPrice['minLos'], roomPrice['ratePlan'] ? roomPrice['ratePlan']['code'] : '');
            return;
        }

        if (!currency && adults) {
            field.setAttribute(ADULTS_ATTRIBUTE, adults);
            field.innerHTML = getLangAdults(adults);
            return;
        }

        if (!currency) return;

        field.innerHTML = getFormatPrice(roomPrice['price'][currency]);
    });
};