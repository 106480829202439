import {PLACEHOLDER, SITE_LANG} from "../const";
import {isDemoMode} from "../utils";
import {LANG_SETTING} from "../settings";

export const getOfferText = (offer) => LANG_SETTING[SITE_LANG].OfferText.replace(PLACEHOLDER.Offer, offer);

export const getLangOffer = (offer) => {
    offer = offer[SITE_LANG];

    if (isDemoMode()) {
        return offer;
    }

    return (offer) ? getOfferText(offer) : offer;
};